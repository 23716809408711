import React from "react";

function Skills() {
    return (
        <div className="row skill-wrapper">
            <div className="col-sm-6">
                <div className="skill">
                    <h4>Drupal</h4>
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" data-transitiongoal="100"
                            style={{width:'90%'}}></div>

                    </div>
                </div>
                <div className="skill">
                    <h4>NodeJS</h4>
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" data-transitiongoal="75"
                            style={{width:'25%'}}></div>

                    </div>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="skill">
                    <h4>Javascript</h4>
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" data-transitiongoal="82"
                            style={{width:'50%'}}></div>

                    </div>
                </div>
                <div className="skill">
                    <h4>Jquery</h4>
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" data-transitiongoal="66"
                            style={{width:'60%'}}></div>

                    </div>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="skill">
                    <h4>PHP</h4>
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" data-transitiongoal="97"
                            style={{width:'90%'}}></div>

                    </div>
                </div>
                <div className="skill">
                    <h4>MysQL</h4>
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" data-transitiongoal="45"
                            style={{width:'70%'}}></div>

                    </div>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="skill">
                    <h4>DevOps</h4>
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" data-transitiongoal="45"
                            style={{width:'60%'}}></div>

                    </div>
                </div>
                <div className="skill">
                    <h4>HTML/CSS</h4>
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" data-transitiongoal="75"
                            style={{width:'75%'}}></div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Skills;
import React, { useState } from 'react';
import About from './components/About';
import DownloadCV from './components/DownloadCV';
import ContactMe from './components/ContactMe';
import Skills from './components/Skills';
import Socials from './components/Socials';
import profilepic from './assets/img/headshot-transparent.png';
import './assets/css/bootstrap.min.css';
import './assets/css/rd-style.css';
import './assets/css/rd-responsive.css';


function App() {
  const [currentPage, setCurrentPage] = useState('home');
  //const [isActive, setActive] = useState(false);

  const renderPage = () => {
    switch (currentPage) {
      case 'about':
        return <About />;
      case 'downloadcv':
        return <DownloadCV />;
      case 'skills':
        return <Skills />;
      case 'socials':
        return <Socials />;
      case 'contactme':
        return <ContactMe />;
      default:
        return <About />;
    }
  };

  // const toggleClass = () => {
  //   setActive(!isActive);
  // };
  

  return (
    <div id="hero" className="hero">
        <div className="container">
            <div className="row main">
                <div className="col-md-6">
                    <div className="profile-photo-wrapper">
                        <div className="profile-photo-center">
                            <div className="profle-photo-inner">
                                <img className="img-responsive" src={profilepic} alt="ranjan-daswani" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <h1>Ranjan Daswani</h1>
                    <div className="page-scroll">
                        <p className="job-title">Web Developer</p>
                        <a href="#about" className="btn btn-border" onClick={() => setCurrentPage('about')}>About</a>
                        <a href="#downloadcv" className="btn btn-border" onClick={() => setCurrentPage('downloadcv')}>Download CV</a>
                        <a href="#contactme" className="btn btn-border" onClick={() => setCurrentPage('contactme')}>Contact me</a>
                        <a href="#skills" className="btn btn-border" onClick={() => setCurrentPage('skills')}>Skills</a>
                        <a href="#socials" className="btn btn-border" onClick={() => setCurrentPage('socials')}>Social links</a>
                    </div>

                    <div className="content-area">
                      {renderPage()}
                    </div>
                </div>

            </div>
        </div>
    </div>
  );
}

export default App;

import React from 'react';

function ContactMe() {
    return (
    <div>
        <p className="error-alert">Due to some technical issue form submission is disabled.</p>
        <form method="POST">
            <div className="row">
                <div className="col-sm-12">
                    <input type="text" name="name" className="form-control mt-x-0" placeholder="Name" required />
                </div>
                <div className="col-sm-12">
                    <input type="email" name="email" className="form-control" placeholder="Email" required />   
                </div>
                <div className="col-sm-12">
                    <textarea name="message" id="mesaage" className="form-control" placeholder="Message" required></textarea>
                </div>
            </div>
            <button href="#" className="btn btn-border" type="submit" disabled>Submit <span className="glyphicon glyphicon-send"></span></button>
        </form>
    </div>
    );
}

export default ContactMe;
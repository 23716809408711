import React from "react";
import linkedin_icon from '../assets/img/linkedin.png';
import stackoverflow_icon from '../assets/img/stack-overflow.png';
import insta_icon from '../assets/img/instagram.png';

function Socials() {
    return (
        <div className="links">
            <a className="linkedin icon" target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/ranjan-daswani-19ab5a77"><img src={linkedin_icon} alt='linkedin_icon' className="social-links" /></a>
            <a className="stackoverflow icon" target="_blank" rel="noreferrer" href="https://stackoverflow.com/users/9085203/ranjan"><img src={stackoverflow_icon} alt='stackoverflow_icon' className="social-links" /></a>
            <a className="insta icon" target="_blank" rel="noreferrer" href="https://www.instagram.com/ranjan_daswani/"><img src={insta_icon} alt='insta_icon' className="social-links" /></a>
        </div>
    );
}

export default Socials;